import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUpload } from '@fortawesome/free-solid-svg-icons';
import './App.css';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ReactMarkdown from 'react-markdown';
import ClassOfEvidence from './ClassOfEvidence';

interface Answer {
    question: string;
    options: string[];
    counts: Record<string, number>;
}

const App: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [analysis, setAnalysis] = useState('');
    const [annotations, setAnnotations] = useState('');
    const [answers, setAnswers] = useState<Answer[] | null>(null);
    const [isFileHovering, setIsFileHovering] = useState(false);
    const [statusMessages, setStatusMessages] = useState('');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const renderCharts = (answers: Answer[]) => {
        return (
            <div className="charts-grid">
                {answers.map((answer, index) => {
                    const data = {
                        labels: answer.options,
                        datasets: [{
                            data: answer.options.map(option => answer.counts[option]),
                            backgroundColor: '#684e94'
                        }]
                    };

                    const options = {
                        maintainAspectRatio: true,
                        aspectRatio: 2,
                        scales: {
                            x: {
                                ticks: {
                                    autoSkip: false,
                                },
                                grid: {
                                    display: false
                                }
                            },
                            y: {
                                beginAtZero: true,
                                ticks: {
                                    stepSize: 1
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false
                            },
                            title: {
                                display: true,
                                text: answer.question,
                                font: {
                                    size: 14
                                },
                                padding: {
                                    top: 20,
                                    bottom: 20
                                }
                            }
                        }
                    };

                    return (
                        <div key={index} className="chart-container">
                            <Bar data={data} options={options} />
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] ?? null;
        if (file) {
            processFile(file);
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsFileHovering(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        setIsFileHovering(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsFileHovering(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            processFile(file);
        }
    };

    const processFile = (file: File) => {
        handleFileUpload(file);
    };

    const handleFileUpload = async (file: File) => {
        setIsLoading(true);
        setStatusMessages("Uploading file...");
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch('/upload', { method: 'POST', body: formData });
        const { analysisId } = await response.json();
        setStatusMessages("File uploaded, processing...");

        const pollInterval = setInterval(async () => {
            const statusResponse = await fetch(`/status/${analysisId}`);
            const status = await statusResponse.json();

            setStatusMessages(status.message);

            if (status.status === 'complete') {
                clearInterval(pollInterval);
                setIsLoading(false);
                setAnalysis(status.analysis.analysis);
                setAnnotations(status.analysis.annotations);
                setAnswers(status.answers);
            }
        }, 5000);
    };

    return (
        <div 
            className="App" 
            onDrop={handleDrop} 
            onDragOver={handleDragOver} 
            onDragLeave={handleDragLeave}
        >
            <nav style={{ padding: '20px', textAlign: 'center', borderBottom: '2px solid ivory' }}>
                <a href="/" style={{ textDecoration: 'none', color: 'ivory', padding: '10px' }}>
                    <FontAwesomeIcon icon={faHome} size="2x" /> Home
                </a>
            </nav>
            <header className="App-header">
                <input 
                    type="file" 
                    style={{ display: 'none' }} 
                    ref={fileInputRef} 
                    onChange={handleFileChange} 
                />
                <button 
                    onClick={handleClick} 
                    style={{ 
                        background: 'none', 
                        cursor: isLoading ? 'default' : 'pointer', 
                        color: 'ivory', 
                        border: '8px solid', 
                        margin: '20px',
                        borderColor: isFileHovering || isLoading ? '#98FB98' : 'ivory', 
                        transition: 'border-color 0.6s ease-in-out',
                        borderRadius: '80px', 
                        padding: '20px',
                        animation: isLoading ? 'border-pulse 3s infinite' : 'none'
                    }}
                >
                    <FontAwesomeIcon icon={faUpload} size="3x" />
                </button>
                {statusMessages && (
                    <div className="status-message">{statusMessages}</div>
                )}
                {answers && <ClassOfEvidence answers={answers} />}
                {analysis && (
                    <div className="analysis-box">
                        <ReactMarkdown>{analysis}</ReactMarkdown>
                    </div>
                )}
                {answers && renderCharts(answers)}
            </header>
        </div>
    );
}

export default App;
