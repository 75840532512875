import React from 'react';

interface Answer {
    question: string;
    options: string[];
    counts: Record<string, number>;
}

interface ClassOfEvidenceProps {
    answers: Answer[];
}

const ClassOfEvidence: React.FC<ClassOfEvidenceProps> = ({ answers }) => {
    const calculateClassOfEvidence = (answers: Answer[]) => {
        let score = 0;
        const maxScore = 11;

        answers.forEach(answer => {
            switch (answer.question) {
                case "How were the outcomes of patients receiving the treatment compared to the outcomes of patients not receiving that treatment?":
                    if (answer.counts.Controlled > 2) score += 2;
                    break;
                case "Were patients in different treatment arms similar in characteristics that could affect outcomes?":
                    if (answer.counts.Similar > 2) score += 1;
                    break;
                case "Were patients randomly assigned to treatment groups?":
                    if (answer.counts.Random > 2) score += 2;
                    break;
                case "Was treatment allocation concealed?":
                    if (answer.counts.Concealed > 2) score += 1;
                    break;
                case "How were outcomes assessed?":
                    if (answer.counts.Masked > 2) score += 1;
                    break;
                case "Was a primary outcome specified?":
                    if (answer.counts["Primary specified"] > 2) score += 1;
                    break;
                case "Were less than 20% of patients enrolled into the study lost to follow-up?":
                    if (answer.counts["Less than 20% lost"] > 2) score += 1;
                    break;
                case "Was the effect of the intervention analyzed according to which treatment group patients were originally assigned?":
                    if (answer.counts["Intent-to-treat"] > 2) score += 2;
                    break;
                default:
                    break;
            }
        });

        let classOfEvidence = '';
        if (score >= 9) {
            classOfEvidence = 'Class I';
        } else if (score >= 6) {
            classOfEvidence = 'Class II';
        } else if (score >= 3) {
            classOfEvidence = 'Class III';
        } else {
            classOfEvidence = 'Class IV';
        }

        return { classOfEvidence, score, maxScore };
    };

    const { classOfEvidence, score, maxScore } = calculateClassOfEvidence(answers);

    return (
        <div className="class-of-evidence">
            <h2>Class of Evidence: {classOfEvidence}</h2>
            <p>Total Score: {score}</p>
            <p>Maximum Possible Score: {maxScore}</p>
        </div>
    );
};

export default ClassOfEvidence;
